const themes = ['light', 'dark'] as const;

export type Theme = (typeof themes)[number];

const themeKey = 'theme';

export function getTheme(): string | null {
  return localStorage.getItem(themeKey);
}

export function setTheme(theme: Theme): void {
  localStorage.setItem(themeKey, theme);
}

export function applyTheme(theme: Theme): void {
  if (typeof document === 'undefined') return;

  const prevTheme = theme === 'dark' ? 'light' : 'dark';

  const {
    documentElement: { classList },
  } = document;

  classList.remove(prevTheme);
  classList.add(theme);

  setTheme(theme);
}

export function isTheme(theme: string | null): theme is Theme {
  return !!theme && ['light', 'dark'].includes(theme);
}
