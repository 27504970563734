'use client';

import classNames from 'classnames';
import { Link } from '@reshima/navigation-ui';
import { Dropdown } from '@reshima/pure-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { Dictionary } from '@reshima/translations';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { UserData } from '@reshima/firebase';
import { AccountMenuImage } from './account-menu-image';

interface MenuItem {
  label: string;
  path: string;
}

function getAdminMenu({
  dictionary,
  userData,
}: {
  dictionary: Dictionary;
  userData?: UserData;
}): MenuItem[] {
  const {
    inflections,
    menuCatalog,
    itemsCatalog,
    usersSearchesItems,
    itemsCategories,
  } = dictionary['account-menu'];

  if (userData?.isAdmin) {
    return [
      {
        label: inflections,
        path: 'catalog/inflections',
      },
      {
        label: menuCatalog,
        path: 'catalog/menu',
      },
      {
        label: itemsCatalog,
        path: 'catalog/items',
      },
      {
        label: usersSearchesItems,
        path: 'users-searches-items',
      },
      {
        label: itemsCategories,
        path: 'items-categories',
      },
    ];
  }

  return [];
}

export default function AccountMenu() {
  const name = 'AccountMenu';
  const dictionary = useTranslations();
  const { user, userData, userLoading } = useClientAuth();

  const { ariaLabel, signIn, account, myLists, signOut } =
    dictionary['account-menu'];

  const menuItems: MenuItem[] = [
    ...(user ? [{ label: myLists, path: 'lists' }] : []),
    ...(!user?.isSignedIn ? [{ label: signIn, path: 'signin' }] : []),
    ...(user?.isSignedIn ? [{ label: account, path: 'account' }] : []),
    ...getAdminMenu({ dictionary, userData }),
    ...(user ? [{ label: signOut, path: 'signout' }] : []),
  ];

  function track(action: Action.Open | Action.Close) {
    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
    });
  }

  return (
    <Dropdown
      circle
      ghost
      ariaLabel={ariaLabel}
      disabled={userLoading}
      onOpen={() => track(Action.Open)}
      onClose={() => track(Action.Close)}
      container={<AccountMenuImage />}
    >
      <ul>
        {menuItems.map(({ label, path }) => (
          <li key={path}>
            <Link
              key={path}
              href={path}
              className={classNames(
                'block py-2 px-3',
                'rounded-lg',
                'hover:bg-base-200',
              )}
              onClick={() => {
                trackEvent({
                  name,
                  action: Action.Click,
                  actionModifier: ActionModifier.Start,
                  properties: { path, label },
                });
              }}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </Dropdown>
  );
}
