'use client';

import { Link } from '@reshima/navigation-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';

const brand = 'רְשִׁימָה';

export default function Brand() {
  const name = 'Brand';
  return (
    <Link
      className="text-xl font-semibold"
      href={`/`}
      onClick={() =>
        trackEvent({
          name,
          action: Action.Click,
          actionModifier: ActionModifier.End,
          properties: { brand },
        })
      }
    >
      <div className="flex gap-2" dir="ltr">
        {brand}
      </div>
    </Link>
  );
}
