import { AiOutlineLoading } from 'react-icons/ai';
import { LiaUserNinjaSolid, LiaUserSolid } from 'react-icons/lia';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { useState } from 'react';

export function AccountMenuImage() {
  const { profileImageAltText } = useTranslations()['account-menu'];
  const [userImageError, setUserImageError] = useState(false);
  const { user, userLoading } = useClientAuth();

  function getIcon() {
    if (userLoading) {
      return (
        <div className="p-2">
          <AiOutlineLoading className="animate-spin" />
        </div>
      );
    }

    if (!user) {
      return (
        <div className="p-2">
          <LiaUserSolid />
        </div>
      );
    }

    if (user.isAnonymous) {
      return (
        <div className="p-2">
          <LiaUserNinjaSolid />
        </div>
      );
    }

    if (user.userImage && !userImageError) {
      return (
        <div className="p-1">
          <img
            src={user.userImage}
            onError={() => setUserImageError(true)}
            referrerPolicy="no-referrer"
            className="rounded-full w-8 h-8"
            alt={profileImageAltText}
          />
        </div>
      );
    }

    return (
      <div className="p-2">
        <LiaUserSolid />
      </div>
    );
  }

  return <div className="text-2xl">{getIcon()}</div>;
}
