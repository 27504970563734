import { useTranslations } from '@reshima/translations-ui';
import { Link } from '@reshima/navigation-ui';

export function Footer() {
  const { version, contact, privacyPolicy } = useTranslations()['footer'];
  return (
    <footer className="flex flex-col gap-1 items-center p-1 xs:p-2 bg-base-200 text-sm">
      <div>
        <Link className="underline" href="/contact">
          {contact}
        </Link>
        <span className="select-none"> | </span>
        <Link className="underline" href="/privacy">
          {privacyPolicy}
        </Link>
      </div>
      <div>
        {version} {process.env.NEXT_PUBLIC_COMMIT_SHA}
      </div>
    </footer>
  );
}
