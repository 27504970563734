import { ThemeToggler } from '@reshima/theme';
import AccountMenu from './account-menu';
import Brand from './brand';

export function NavBar() {
  return (
    <nav className="sticky z-10 top-0 w-full bg-base-200 border-b border-base-300">
      <div className="max-w-screen-md mx-auto">
        <div className="w-full flex items-center justify-between p-0.5 xs:px-2">
          <ThemeToggler />
          <Brand />
          <AccountMenu />
        </div>
      </div>
    </nav>
  );
}
