'use client';
import {
  PropsWithChildren,
  FC,
  useState,
  createContext,
  useContext,
} from 'react';
import { Theme, getTheme, isTheme, applyTheme } from './shared';

interface ThemeContext {
  theme: Theme;
  setTheme?: (theme: Theme) => void;
}

function getInitialTheme(): Theme {
  if (typeof window !== 'undefined') {
    const theme = getTheme();
    if (isTheme(theme)) {
      return theme;
    }
  }
  return 'light';
}

export const themeContext = createContext<ThemeContext>({
  theme: getInitialTheme(),
});

export function useTheme(): ThemeContext {
  return useContext(themeContext);
}

export function ThemeProvider({ children }: PropsWithChildren): ReturnType<FC> {
  const [theme, setTheme] = useState<Theme>(getInitialTheme);

  applyTheme(theme);

  return (
    <themeContext.Provider
      value={{
        theme,
        setTheme: (theme) => {
          applyTheme(theme);
          setTheme(theme);
        },
      }}
    >
      {children}
    </themeContext.Provider>
  );
}
